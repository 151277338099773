import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {endpoints} from '../../environments/environment';
import {iif, Observable, of} from 'rxjs';

export interface EnvAuth {
  appId: number;
  accessToken: string;
  accessTokenActiveTill: string;
  refreshToken: string;
}

export interface AuthResponse {
  accessToken: string;
  email: string;
  refreshToken: string;
  accessTokenActiveTill: string;
  envAuths: EnvAuth[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private http: HttpClient) {
  }

  login(email: string, password: string) {
    return this.http.post<AuthResponse>(endpoints.auth.signIn, {email, password}).pipe(
      tap(this.setSession),
      shareReplay(),
    );
  }

  refresh() {
    const RefreshToken = localStorage.getItem('.refreshToken') as string;
    const email = localStorage.getItem('email') as string;
    return this.http.post<AuthResponse>(endpoints.auth.refresh, {email}, {headers: {RefreshToken}}).pipe(
      tap(this.setSession),
      shareReplay(),
    );
  }

  signOut() {
    return this.http.post(endpoints.auth.signOut, null).pipe(tap(() => {
      this.clearSessionInfo();
    }));
  }

  isSignedIn(): Observable<boolean> {
    return iif<boolean, boolean>(
      () => this.isTokenValid(),
      of(true),
      this.refresh().pipe(
        map(() => true),
        catchError(() => {
          this.clearSessionInfo();
          return of(false);
        }),
      ),
    );
  }

  getEmail(): string {
    return localStorage.getItem('email') as string;
  }

  getAppEnvAuth(appId: number): Partial<EnvAuth> {
    return {
      appId,
      accessToken: localStorage.getItem(`${appId}.accessToken`) ?? undefined,
      accessTokenActiveTill: localStorage.getItem(`${appId}.accessTokenActiveTill`) ?? undefined,
      refreshToken: localStorage.getItem(`${appId}.refreshToken`) ?? undefined,
    }
  }

  private clearSessionInfo() {
    localStorage.removeItem('.accessTokenActiveTill');
    localStorage.removeItem('.accessToken');
    localStorage.removeItem('.refreshToken');
    localStorage.removeItem('email');
  }

  private isTokenValid(): boolean {
    return this.getExpiration() > new Date().getTime();
  }

  private setSession(authResult: AuthResponse) {
    localStorage.setItem('.accessTokenActiveTill', authResult.accessTokenActiveTill);
    localStorage.setItem('.accessToken', authResult.accessToken);
    localStorage.setItem('.refreshToken', authResult.refreshToken);
    authResult.envAuths.forEach((envAuth) => {
      localStorage.setItem(`${envAuth.appId}.accessTokenActiveTill`, envAuth.accessTokenActiveTill);
      localStorage.setItem(`${envAuth.appId}.accessToken`, envAuth.accessToken);
      localStorage.setItem(`${envAuth.appId}.refreshToken`, envAuth.refreshToken);
    })
    localStorage.setItem('email', authResult.email);
  }

  private getExpiration(): number {
    const existingExpirationTime = localStorage.getItem('.accessTokenActiveTill');
    return existingExpirationTime ? new Date(existingExpirationTime).getTime() : (new Date()).getTime();
  }
}
